import React from "react"
import "./ContactPage.scss"
import Layout from "../layout"
import Contact from "./Contact/Contact"
import { useStaticQuery } from "gatsby"

export default function ContactPage() {
  const contactQuery = useStaticQuery(graphql`
    query {
      allStrapiContactinformation {
        edges {
          node {
            ContactSubtitle
            contactType
            contactTitle
            contactLogo {
              publicURL
            }
          }
        }
      }
    }
  `)
  const contactInformation = contactQuery.allStrapiContactinformation.edges
  console.log(contactInformation)

  return (
    <Layout>
      <div className="contact_heroPage">
        <div className="headerBox">
          <div className="headerTextBox">
            <h1 className="has-text-centered title" style={{ paddingTop: 50 }}>
              Contact our Support Team
            </h1>
            <h2 className="has-text-centered" style={{ paddingTop: 10 }}>
              Need to get in touch ?
            </h2>
          </div>
        </div>
        <div className="InfoCard_email">
          <h1 className="has-text-centered title">Email us on</h1>

          {contactInformation
            .filter(info => info.node.contactType === "email")

            .map(email => {
              return (
                <div className="location_wrapper">
                  <div className="contact-tile__icon">
                    <img src={email.node.contactLogo.publicURL} />
                  </div>
                  <div style={{ marginLeft: 15 }}>
                    <h2 className="locationTitle">{email.node.contactTitle}</h2>
                    <a href={`mailto:${email.node.ContactSubtitle}`}>
                      {email.node.ContactSubtitle}
                    </a>
                  </div>
                </div>
              )
            })}
        </div>
        <div className="InfoCard_location">
          <h1 className="has-text-centered title" style={{ marginBottom: 35 }}>
            Office locations
          </h1>
          {contactInformation
            .filter(info => info.node.contactType === "location")
            .map(location => {
              return (
                <div className="location_wrapper">
                  <div className="contact-tile__icon">
                    <img src={location.node.contactLogo.publicURL} />
                  </div>
                  <div style={{ marginLeft: 15 }}>
                    <p className="locationTitle">
                      {location.node.contactTitle}
                    </p>
                    <p>{location.node.ContactSubtitle}</p>
                  </div>
                </div>
              )
            })}
        </div>
      </div>
    </Layout>
  )
}
